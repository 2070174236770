<template>
  <v-container fluid class="px-lg-5">
    <app-modal
      v-show="isModalVisible"
      :modal="modal"
      @submit-modal="submitModal"
      @date-changed="onDateChange"
    />
    <app-header
      :title="title"
      :breadcrumbs="breadcrumbs"
      :actionButton="actionButton"
      @clicked="openCreateHolidayModal"
      :showButton="true"
    />
    <table-filter
      @filterTable="filterTable"
      :items="yearOptions"
      :label="$t('models.holiday.attributes.year')">
    </table-filter>
    <v-card class="rounded-card" flat outlined>
      <v-data-table
        :headers="headers"
        :items="holidays.items"
        :options.sync="options"
        @update:options="updateOptions"
        :server-items-length="holidays.count"
        :loading="loading"
        :no-data-text="$t('messages.emptyState', { entity: $tc('models.holiday.entity') })"
        :no-results-text="$t('messages.emptyState', { entity: $tc('models.holiday.entity') })"
        flat
      >
        <template v-slot:[`item.officeId`]="{ item }">
          {{ item.officeName }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small @click="editHoliday(item)">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn icon small @click="deleteHoliday(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';

export default {
  name: 'officialHolidays',
  data() {
    return {
      loading: false,
      isModalVisible: false,
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          name: {
            type: 'text',
            key: 'name',
            value: '',
            label: this.$t('models.holiday.attributes.name'),
            rules: [{name: 'required'}],
            classes: 'col-md-6 col-sm-12 col-12 py-0',
            serverErrors: []
          },
          officeId: {
            type: 'select',
            key: 'officeId',
            value: '',
            options: [],
            itemText: 'name',
            itemValue: 'id',
            rules: [{name: 'requiredSelect'}],
            placeholder: this.$t('models.holiday.attributes.office'),
            classes: 'col-md-6 col-sm-12 col-12 py-0',
            serverErrors: []
          },
          startDate: {
            type: 'datepicker',
            key: 'startDate',
            itemText: 'value',
            itemValue: 'key',
            value: '',
            label: this.$t('models.holiday.attributes.startDate'),
            trackable: true,
            rules: [{name: 'required'}],
            classes: 'col-md-6 col-sm-12 col-12 py-0',
            serverErrors: []
          },
          endDate: {
            type: 'datepicker',
            key: 'endDate',
            itemText: 'value',
            itemValue: 'key',
            value: '',
            label: this.$t('models.holiday.attributes.endDate'),
            trackable: true,
            rules: [{name: 'minEnd'}],
            classes: 'col-md-6 col-sm-12 col-12 py-0',
            serverErrors: []
          }
        }
      },
      title: {
        text: this.$t('layout.mainMenu.officialHolidays'),
        icon: 'mdi-calendar-star'
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.officialHolidays'),
          to: {name: 'OfficialHolidays'},
          exact: true
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['startDate'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false,
        filterBy: {year: moment().year()}
      },
      actionButton: {
        text: this.$t('views.holidays.newHoliday'),
        icon: 'mdi-plus'
      },
      headers: [
        {
          text: this.$t('models.holiday.attributes.name'),
          value: 'name'
        },
        {
          text: this.$t('models.holiday.attributes.office'),
          value: 'officeId'
        },
        {
          text: this.$t('models.holiday.attributes.startDate'),
          value: 'startDate'
        },
        {
          text: this.$t('models.holiday.attributes.endDate'),
          value: 'endDate'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '120px'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['offices', 'holidays']),
    yearOptions() {
      let currentYear = moment().year();
      return Array(3).fill().map(() => currentYear--);
    }
  },
  methods: {
    ...mapActions([
      'getOffices',
      'getHolidays',
      'destroyHoliday',
      'createHoliday',
      'updateHoliday'
    ]),
    filterTable(year) {
      this.options.filterBy.year = year;
      this.updateOptions(this.options);
    },
    updateOptions(options) {
      this.loading = true;
      this.getHolidays(options).then(() => (this.loading = false));
    },
    submitModal(data) {
      const request = this.modal.behaviour === 'createHoliday' ? this.createHoliday(data) :
        this.updateHoliday({id: this.modal.id, form: data});

      request.then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
          this.updateOptions(this.options);
        } else {
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    },
    editHoliday(data) {
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.behaviour = 'updateHoliday';
      this.modal.title = this.$t('views.holidays.updateHoliday');
      this.modal.fields.name.value = data.name;
      this.modal.fields.startDate.value = moment(data.startDate, 'DD MMM YYYY').format('YYYY-MM-DD');
      this.modal.fields.endDate.minDate = this.modal.fields.startDate.value;
      this.modal.fields.endDate.value = moment(data.endDate, 'DD MMM YYYY').format('YYYY-MM-DD');
      this.modal.fields.startDate.maxDate = this.modal.fields.endDate.value;
      this.modal.fields.officeId.value = `${data.officeId}`;
      this.modal.id = data.id;
      this.modal.show = true;
    },
    openCreateHolidayModal() {
      this.modal.resetForm = true;
      this.modal.title = this.$t('views.holidays.newHoliday');
      this.modal.behaviour = 'createHoliday';
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = '';
      });
      this.modal.fields.startDate.maxDate = '';
      this.modal.fields.endDate.minDate = '';
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    deleteHoliday(holiday) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: holiday.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyHoliday(holiday.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    },
    onDateChange($event) {
      if ($event.key === 'startDate') {
        this.modal.fields.endDate.minDate = $event.value;
      } else if ($event.key === 'endDate') {
        this.modal.fields.startDate.maxDate = $event.value;
      }
    }
  },
  created() {
    this.getOffices({fields: ['name']}).then(() => {
      this.modal.fields.officeId.options = this.offices.items;
    });
  }
};
</script>
